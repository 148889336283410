<template>
  <div class="card shadow"
       :class="type === 'dark' ? 'bg-default': ''">
    <div class="card-header border-0"
         :class="type === 'dark' ? 'bg-transparent': ''">
      <div class="row align-items-center">
        <div class="col">
          <h3 class="mb-0" :class="type === 'dark' ? 'text-white': ''">
            {{title}}
          </h3>
        </div>
        <div class="col text-right">
          <base-button class="mb-0" type="primary" @click="showAddModal()">Buat Data User</base-button>
        </div>
      </div>
    </div>

    <div class="table-responsive">
      <div style="text-align:center;width:100%" v-if="loadingData">Sedang memuat data ....</div>
      <base-table v-else class="table align-items-center table-flush"
                  :class="type === 'dark' ? 'table-dark': ''"
                  :thead-classes="type === 'dark' ? 'thead-dark': 'thead-light'"
                  tbody-classes="list"
                  :data="tableData">
        <template slot="columns">
          <th>Nama</th>
          <th>Username</th>
          <th>Role</th>
          <th>Status</th>
          <th></th>
        </template>

        
        <template slot-scope="{row}">
          <th scope="row">
            <div class="media align-items-center">
              <div class="media-body">
                <span class="name mb-0 text-sm">{{row.name}}</span>
              </div>
            </div>
          </th>
          <td class="budget">
            {{row.username}}
          </td>
          <td class="budget">
            {{row.role == 1 ? "Admin" : row.role == 2 ? "Operator" : row.role == 5 ? "Viewer" : "PIC"}}
          </td>
          <td class="budget">
            <badge :rounded="true" :type="(row.deleted_at)?'danger':'primary'">{{(row.deleted_at)?'nonaktif':'aktif'}}</badge>
          </td>
          <td>
            <div class="media-body budget">
                <!-- <base-button v-if="row.role == 4" type="primary">List Mitra</base-button> -->
                <!-- <base-button @click="resetPass" type="info">Reset Password</base-button> -->
                
                <base-button @click="showEditModal(row)" size="sm" type="info">Edit</base-button>
                <base-button @click="showResetPasswordModal(row)" size="sm" type="warning">Reset Password</base-button>
                <base-button @click="showNonAktifModal(row)" v-if="!row.deleted_at" size="sm" type="danger">Nonaktifkan</base-button>
                <base-button @click="showAktifModal(row)" v-else size="sm" type="primary">Aktifkan</base-button>
              </div>
          </td>

        </template>

      </base-table>
    </div>

    <div class="card-footer d-flex justify-content-end"
         :class="type === 'dark' ? 'bg-transparent': ''">
      <base-pagination :total="meta.total"
                       :perPage="meta.per_page"
                       v-model="page"
      ></base-pagination>
    </div>
    <modal :show.sync="modalStatus"
            body-classes="p-0"
            modal-classes="modal-dialog-centered modal-sm">
        <card type="secondary" shadow
              header-classes="bg-white pb-5"
              body-classes="px-lg-5 py-lg-5"
              class="border-0">
            <template>
                <div class="text-muted mb-4">
                    <h1 v-if="modal.edit">Edit User</h1>
                    <h1 v-else-if="modal.add">Tambah User</h1>
                    <form role="form">
                      <base-input alternative=""
                                  label="Role">
                        <v-select :options="option" v-model="selected"></v-select>
                      </base-input>
                      <base-input alternative
                                  label="Nama"
                                  :error="errors.name"
                                  :valid="(errors.name)?false:null"
                                  v-if="selected != null"
                                  class="mb-3"
                                  placeholder="Nama"
                                  v-model="model.name">
                      </base-input>
                      <base-input alternative
                                  label="Username"
                                  v-if="selected != null"
                                  class="mb-3"
                                  placeholder="Username"
                                  :error="errors.username"
                                  :valid="(errors.username)?false:null"
                                  v-model="model.username">
                      </base-input>
                      <base-input  alternative
                                  label="Password"
                                  v-if="selected != null && modal.add && selected.role != '4'"
                                  class="mb-3"
                                  type="password"
                                  placeholder="Password"
                                  :error="errors.password"
                                  :valid="(errors.password)?false:null"
                                  v-model="model.password">
                      </base-input>
                      <base-input alternative
                                  label="No Telp"
                                  v-if="selected != null && selected.role == '4'"
                                  class="mb-3"
                                  placeholder="No Telp"
                                  :error="errors.pic_phone"
                                  :valid="(errors.pic_phone)?false:null"
                                v-model="model.pic_phone">
                      </base-input>
                    <div class="text-center">
                        <base-button type="primary" class="my-4" @click="(modal.edit)?updateData():saveData()" :disabled="loadingProcess">
                          <span v-if="loadingProcess">Menyimpan...</span><span v-else>Simpan</span>
                        </base-button>
                        <base-button type="danger" class="my-4" @click="closeModal((modal.edit)?'edit':'add')">Batal</base-button>
                    </div>
                </form>
                </div>
                
            </template>
        </card>
    </modal>

  </div>
</template>
<script>
  export default {
    name: 'projects-table',
    props: {
      type: {
        type: String
      },
      title: String
    },
    data() {
      return {
        tableData: [
        ],
        role: '',
        meta: {
          total : 0,
          per_page : 0,
        },
        page: 1,
        userAdd : false,
        option:[
          { label : "Admin", role : "1" },
          { label : "Operator", role : "2" },
          { label : "PIC", role : "4" },
          { label : "Viewer", role : "5" }
        ],
        model:{
          name : '',
          role: '',
          username:'',
          pic_phone:'',
          password: '',
        },
        loadingData : true,
        loadingProcess : false,
        selected: null,
        modal:{
          add:false,
          edit:false
        },
        errors:[] 
      }
    },
    
    mounted() {
      this.role = localStorage.getItem("role");
      this.getData()
    },
    watch:{
      page(newPage){
        this.getData(newPage)
      }
    },
    computed:{
      modalStatus: {
        get(){
          return this.modal.add || this.modal.edit
        },
        set(status){
          return status
        } 
      }
    },
    methods:{
      showNonAktifModal(value){
        let self = this
        this.$swal({
          title: 'Nonaktifkan akun',
          text: 'Apakah anda yakin nonaktifkan user ini?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ya',
          cancelButtonText: 'Tidak'
        }).then((result) => {
          if (result.isConfirmed) {
            self.nonAktifData(value.id)
          }
        });
      },
      nonAktifData(id){
        this.$http
        .delete("/api/users/"+id)
        .then((response) => {
          this.$swal({
            title: "Berhasil",
            text: response.data.info,
            icon: "success"
          });
          this.getData()
        }).catch((error) => {
          this.errors = error.response.data.error
          this.$swal({
            title: "Gagal",
            text: error.response.data.info,
            icon: "error"
          })
        });
      },
      showAktifModal(value){
        let self = this
        this.$swal({
          title: 'Aktifkan akun',
          text: 'Apakah anda yakin mengaktifkan kembali user ini?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ya',
          cancelButtonText: 'Tidak'
        }).then((result) => {
          if (result.isConfirmed) {
            self.aktifData(value.id)
          }
        });
      },
      aktifData(id){
        this.$http
        .put("/api/users/"+id+"/restore")
        .then((response) => {
          this.$swal({
            title: "Berhasil",
            text: response.data.info,
            icon: "success"
          });
          this.getData()
        }).catch((error) => {
          this.errors = error.response.data.error
          this.$swal({
            title: "Gagal",
            text: error.response.data.info,
            icon: "error"
          })
        });
      },
      showResetPasswordModal(value){
        let self = this
        this.$swal({
          title: 'Reset password akun',
          text: 'Apakah anda yakin mereset password user ini?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ya',
          cancelButtonText: 'Tidak'
        }).then((result) => {
          if (result.isConfirmed) {
            self.resetPassword(value.id)
          }
        });
      },
      resetPassword(id){
        this.$http
        .put("/api/users/"+id+"/reset")
        .then((response) => {
          this.$swal({
            title: "Berhasil",
            text: response.data.info,
            icon: "success"
          });
          this.getData()
        }).catch((error) => {
          this.errors = error.response.data.error
          this.$swal({
            title: "Gagal",
            text: error.response.data.info,
            icon: "error"
          })
        });
      },
      resetPass(){
        this.loadingProcess = true
        this.$swal({
          title: 'Apakah Anda Yakin?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ya',
          cancelButtonText: 'Tidak'
        }).then((result) => {
          if (result.isConfirmed) {
            this.loadingProcess = false
            this.$swal(
              'Berhasil!',
              'Password di-reset.',
              'success'
            )
          }
        });
      },
      showEditModal(value){
        this.$http
        .get("/api/users/"+value.id)
        .then((response) => {
          console.log(response)
          this.errors = []
          this.selectedData = Object.assign({},value)
          Object.keys(this.model).forEach(k => this.model[k] = value[k]);
          if(response.data.data.pic){
            this.model.pic_phone = response.data.data.pic.pic_phone
          }
          this.selected = this.option.find(x => String(x.role) == this.selectedData.role)
          this.modal.edit = true
        }).catch((error) => {
          this.$swal({
            title: "Gagal",
            text: error.response.data.info,
            icon: "error"
          })
        })
        
      },
      showAddModal(){
        this.errors = []
        this.modal.add = true
      },
      resetModel(){
        Object.keys(this.model).forEach(k => this.model[k] = null);
      },
      closeModal(type){
        if(type == 'edit'){
          this.selected = null
          this.modal.edit = false
          this.resetModel()
        }else{
          this.modal.add = false
        }
      },
      getData(page=this.page){
          this.loadingData = true;
          this.$http
          .get("/api/users?page="+page)
          .then((response) => {
            this.tableData = response.data.data;
            this.meta = response.data.meta;
            this.loadingData = false;
          });
      },
      saveData(){
        this.loadingProcess = true
        if(this.selected.role == 1 || this.selected.role == 2 || this.selected.role == 5){
          this.$http
          .post("/api/users", {
            'name' : this.model.name,
            'username' : this.model.username,
            'password' : this.model.password,
            'role' : this.selected.role,
          })
          .then((response) => {
            this.$swal({
              title: "Berhasil",
              text: response.data.info,
              icon: "success"
            })
            this.closeModal('add')
            this.getData()
            this.resetModel()
            this.loadingProcess = false
          }).catch((error) => {
            this.loadingProcess = false
            this.errors = error.response.data.error
            this.$swal({
              title: "Gagal",
              text: error.response.data.info,
              icon: "error"
            })
          });
        } else {
          this.$http
          .post("/api/pics", {
            'name' : this.model.name,
            'username' : this.model.username,
            'pic_name' : this.model.name,
            'pic_phone' : this.model.pic_phone,
          })
          .then((response) => {
            this.$swal({
              title: "Berhasil",
              text: response.data.info,
              icon: "success"
            })
            this.closeModal('add');
            this.loadingData = true;
            this.getData()
            this.loadingProcess = false
          }).catch((error) => {
            this.loadingProcess = false
            this.errors = error.response.data.error
            this.$swal({
              title: "Gagal",
              text: error.response.data.info,
              icon: "error"
            })

          });
        }
      },
      updateData(){
        this.loadingProcess = true
        this.$http
        .put("/api/users/"+this.selectedData.id, {
          'name' : this.model.name,
          'username' : this.model.username,
          'password' : this.model.password,
          'pic_phone' : this.model.pic_phone,
          'role' : this.selected.role,
        })
        .then((response) => {
            this.$swal({
              title: "Berhasil",
              text: response.data.info,
              icon: "success"
            })
            this.closeModal('edit')
            this.getData()
            this.resetModel()
            this.loadingProcess = false
        }).catch((error) => {
          this.loadingProcess = false
          this.errors = error.response.data.error
          this.$swal({
            title: "Gagal",
            text: error.response.data.info,
            icon: "error"
          })
        });
        
      }
    }
  }
</script>
<style>
</style>
