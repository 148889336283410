<template>
    <div class="card">
        <div class="card-body bg-transparent">
            <div class="row align-items-center" >
                <div class="col">
                    <div>
                        <div style="text-align:center;width:100%" v-if="loadingData">Sedang memuat data ....</div>
                        <highcharts v-else :options="grafikBulananOptions" style="width:100%;"></highcharts>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return{
            tableData:[],
            loadingData:false,
            grafikBulananOptions:{
                title: {
                    text: 'Jumlah Rujukan Perbulan'
                },

                subtitle: {
                    text: 'Tahun:'
                },

                yAxis: {
                    title: {
                        text: 'Jumlah Rujukan'
                    }
                },
                xAxis: {
                    categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
                },

                plotOptions: {
                    line: {
                        dataLabels: {
                            enabled: true
                        },
                        enableMouseTracking: false
                    }
                },

                series: [{
                    name: 'Rujukan',
                    data: [43934, 52503, 57177, 69658, 97031, 119931, 137133, 154175]
                }, {
                    name: 'Rujukan telah diregistrasi',
                    data: [24916, 24064, 29742, 29851, 32490, 30282, 38121, 40434]
                }],

                responsive: {
                    rules: [{
                        condition: {
                            maxWidth: 500
                        },
                        chartOptions: {
                            legend: {
                                layout: 'horizontal',
                                align: 'center',
                                verticalAlign: 'bottom'
                            }
                        }
                    }]
                }
            }
        }
    },
    mounted(){
        this.getData()
    },
    methods:{
        getData(){
            this.loadingData = true
            this.$http
            .get("/api/dashboard/bulanan")
            .then((response) => {
                this.loadingData = false
                this.tableData = response.data.data;
                // this.meta = response.data.meta;
                // console.log(this.tableData)
                this.grafikBulananOptions.series[0].data = []
                this.grafikBulananOptions.series[1].data = []
                // let demografiData = []
                Array.prototype.forEach.call(this.tableData, element => {
                      this.grafikBulananOptions.series[0].data.push(element.jumlah)
                      this.grafikBulananOptions.series[1].data.push(element.jumlah_teregistrasi)
                })
                // this.demografiOptions.series[0].data = demografiData
                this.grafikBulananOptions.subtitle.text = 'Tahun: '+response.data.year
            });
        }
    }
}
</script>