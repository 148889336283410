<template>
    <div>
        <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
            <!-- Mask -->
            <span class="mask bg-gradient-success opacity-8"></span>
            <!-- Header container -->
            <div class="container-fluid d-flex align-items-center">
                <div class="row">
                    <div class="col-lg-7 col-md-10">
                    </div>
                </div>
            </div>
        </base-header>

        <div class="container-fluid mt--7">
            <div class="row">
                <div class="col-xl-12 order-xl-1">
                    <card shadow type="secondary">
                        <div slot="header" class="bg-white border-0">
                            <div class="row align-items-center">
                                <div class="col-8">
                                    <h3 class="mb-0">Rujukan Baru</h3>
                                </div>
                            </div>
                        </div>
                        <template>
                            <form @submit.prevent>
                                <div class="pl-lg-4">
                                    <div class="row">
                                        <div class="col-lg-12">
                                            <base-input alternative=""
                                                        label="Tanggal Rujukan"
                                                        placeholder="24-10-2020"
                                                        type="date"
                                                        :valid="(errors.rujukan_date)?false:null"
                                                        input-classes="form-control-alternative"
                                                        v-model="model.date"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <hr class="my-4" />
                                <template v-if="role != 3">
                                    <h6 class="heading-small text-muted mb-4">MITRA</h6>
                                    <base-input alternative=""
                                                label="Mitra">
                                        <v-select :options="optionMitra" v-model="model.mitra"></v-select>
                                    </base-input>
                                </template>
                                <h6 class="heading-small text-muted mb-4">Informasi Pasien</h6>
                                <div class="pl-lg-4">
                                    <div class="row">
                                        <div class="col-lg-6">
                                            <base-input alternative=""
                                                        label="Nama"
                                                        placeholder="Nama"
                                                        input-classes="form-control-alternative"
                                                        v-model="model.name"
                                                        :valid="(errors.pasien_name)?false:null"
                                            />
                                        </div>
                                        <div class="col-lg-6">
                                            <base-input alternative=""
                                                        label="Tanggal Lahir"
                                                        placeholder="24-10-2020"
                                                        type="date"
                                                        :valid="(errors.pasien_dob)?false:null"
                                                        input-classes="form-control-alternative"
                                                        v-model="model.birthdate"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <hr class="my-4" />
                                <!-- Address -->
                                <h6 class="heading-small text-muted mb-4">Informasi Kontak Pasien</h6>
                                <div class="pl-lg-4">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <base-input alternative=""
                                                        label="No Telp"
                                                        :valid="(errors.phone)?false:null"
                                                        placeholder="No Telp"
                                                        input-classes="form-control-alternative"
                                                        v-model="model.phone"
                                            />
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <base-input alternative=""
                                                        label="Alamat"
                                                        :valid="(errors.address)?false:null"
                                                        placeholder="Alamat"
                                                        input-classes="form-control-alternative"
                                                        v-model="model.address"
                                            />
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-6">
                                            <base-input alternative=""
                                                    :valid="(errors.province)?false:null"
                                                        label="Provinsi">
                                                <v-select :options="option_prov" v-model="provinsi"></v-select>
                                            </base-input>
                                        </div>
                                        <div class="col-lg-6">
                                            <base-input alternative=""
                                                    :valid="(errors.kabupaten)?false:null"
                                                        label="Kota/Kabupaten">
                                                <v-select :options="option_kab" v-model="kabupaten"></v-select>
                                            </base-input>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-6">
                                            <base-input alternative=""
                                                    :valid="(errors.kecamatan)?false:null"
                                                    label="Kecamatan">
                                                <v-select :options="option_kec" v-model="kecamatan"></v-select>
                                            </base-input>
                                        </div>
                                        <div class="col-lg-6">
                                            <base-input alternative=""
                                                    :valid="(errors.kelurahan)?false:null"
                                                    label="Desa/Kelurahan">
                                                <v-select :options="option_desa" v-model="kelurahan"></v-select>
                                            </base-input>
                                        </div>
                                    </div>
                                </div>
                                <hr class="my-4" />
                                <!-- Description -->
                                <h6 class="heading-small text-muted mb-4">Diagnosis & Tindakan</h6>
                                <div class="pl-lg-4">
                                    <div class="form-group">
                                        <base-input alternative=""
                                                :valid="(errors.diagnosis_first)?false:null"
                                                label="Diagnosis">
                                            <textarea v-model="model.diagnosis" rows="4" class="form-control form-control-alternative" placeholder="Diagnosis"></textarea>
                                        </base-input>
                                    </div>
                                </div>
                                <div class="pl-lg-4">
                                    <div class="form-group">
                                        <base-input alternative="" 
                                                :valid="(errors.action_first)?false:null"
                                                label="Tindakan Awal">
                                            <textarea v-model="model.tindakan" rows="4" class="form-control form-control-alternative" placeholder="Tindakan yang sudah dilakukan"></textarea>
                                        </base-input>
                                    </div>
                                </div>
                                <div class="pl-lg-4">
                                    <div class="form-group">
                                        <base-button size="md" type="success" :disabled="loadingProcess"  @click="saveData"><span v-if="loadingProcess">Mengirim...</span><span v-else>Kirim</span></base-button>
                                    </div>
                                </div>
                            </form>
                        </template>
                    </card>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import BaseButton from '../components/BaseButton.vue';
import moment from 'moment';
  export default {
    name: 'user-profile',
    components: {
        BaseButton,
    },
    data() {
      return {
        model: {
          date: '',
          name: '',
          birthdate: '',
          phone: '',
          address: '',
          diagnosis: '',
          tindakan: '',
          mitra:''
        },
        provinsi: '',
        kabupaten: '',
        kecamatan: '',
        kelurahan: '',
        option_prov: [],
        option_kab: [],
        option_kec: [],
        option_desa: [],
        optionMitra:[],
        role:0,
        errors:[],
        loadingProcess:false
      }
    },
    mounted(){
        if(localStorage.getItem("tokensirulin") == null){
          this.$router.push({ name: 'login' })
        }
        this.getListMitra()
        this.role = localStorage.getItem("role");
        this.$http
        .get("/api/kode/provinsi")
        .then((response) => {
            response.data.data.forEach(el => {
                this.option_prov.push({label: el.provinsi, provinsi: el.provinsi});
            });
        });
    },
    methods: {
        getListMitra(){
            this.$http
            .get("/api/kliniksOption")
            .then((response) => {
                response.data.data.forEach(el => {
                    this.optionMitra.push({label: el.klinik_name, id: el.id, point: el.point});
                });
                // console.log(this.option);
            });
        },
        saveData(){
        this.$swal({
          title: 'Apakah Anda Yakin?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ya',
          cancelButtonText: 'Tidak'
        }).then((result) => {
            if (result.isConfirmed) {
                this.errors = []
                this.loadingProcess = true
                let rujukan_date = moment(this.model.date).format('YYYY-MM-DD')
                let pasien_dob = moment(this.model.birthdate).format('YYYY-MM-DD')
                this.$http
                .post("/api/rujukans", {
                'rujukan_date' : (rujukan_date == 'Invalid date')?'' : rujukan_date,
                'pasien_name' : this.model.name,
                'pasien_dob' : (pasien_dob == 'Invalid date')?'' : pasien_dob,
                'phone' : this.model.phone,
                'address' : this.model.address,
                'province' : this.provinsi.provinsi,
                'kabupaten' : this.kabupaten.kabupaten,
                'kecamatan' : this.kecamatan.kecamatan,
                'kelurahan' : this.kelurahan.kelurahan,
                'diagnosis_first' : this.model.diagnosis,
                'action_first' : this.model.tindakan,
                'mitra' : this.model.mitra.id,
                })
                .then((response) => {
                if(response.status == 201){
                    this.$swal({
                    title: "Berhasil",
                    text: "Data Rujukan ditambahkan",
                    icon: "success"
                    });
                    this.model = {
                        date: '',
                        name: '',
                        birthdate: '',
                        phone: '',
                        address: '',
                        diagnosis: '',
                        tindakan: '',
                        mitra:''
                    };
                    this.provinsi = '';
                    this.kabupaten = '';
                    this.kecamatan = '';
                    this.kelurahan = '';
                    this.option_kab = [];
                    this.option_kec = [];
                    this.option_desa = [];
                } else {
                    this.$swal({
                    title: "Gagal",
                    text: "Ada Kesalahan Pengiriman Data",
                    icon: "error"
                    })
                }
                this.loadingProcess = false
            }).catch((err) => {
                console.log(err)
                this.loadingProcess = false
                this.errors = err.response.data.error
                this.$swal({
                    title: "Gagal",
                    text: err.response.data.info,
                    icon: "error"
                })
            });
          }
        });
      }
    },
    watch: {
        provinsi(newVal){
            this.kabupaten = ''
            this.$http
            .get("/api/kode/kabupaten/"+newVal.provinsi)
            .then((response) => {
                this.option_kab = []
                response.data.data.forEach(el => {
                    this.option_kab.push({label: el.kabupaten, kabupaten: el.kabupaten});
                });
            });
        },
        kabupaten(newVal){
            this.kecamatan= ''
            this.$http
            .get("/api/kode/kecamatan/"+newVal.kabupaten)
            .then((response) => {
                this.option_kec = []
                response.data.data.forEach(el => {
                    this.option_kec.push({label: el.kecamatan, kecamatan: el.kecamatan});
                });
            });
        },
        kecamatan(newVal){
            this.kelurahan = ''
            this.$http
            .get("/api/kode/kelurahan/"+newVal.kecamatan)
            .then((response) => {
                this.option_desa = []
                response.data.data.forEach(el => {
                    this.option_desa.push({label: el.kelurahan, kelurahan: el.kelurahan});
                });
            });
        }
    }
  };
</script>
<style></style>
