<template>
    <div>
        <template v-if="role == 3">
          <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
              <div class="row">
                <h1 class="ml-3">Selamat Datang!</h1>
              </div>
              <!-- Card stats -->
          </base-header>


          <div class="container-fluid mt--7">
            <div class="row">
              <div class="col-xl-12 mb-5 mb-xl-0">
                <p>Promo Puri Bunda</p>
                  <div class="row justify-content-md-center">
                    <div class="col-xl-4 mt-2 mb-3">
                      <img style="max-width:100%;max-height:100%;" src="@/assets/promo1.jpeg"/>  
                    </div>
                    <div class="col-xl-4 mt-2 mb-3">
                      <img style="max-width:100%;max-height:100%;" src="@/assets/promo2.jpeg"/>  
                    </div>
                    <div class="col-xl-4 mt-2 mb-3">
                      <img style="max-width:100%;max-height:100%;" src="@/assets/promo3.jpeg"/>  
                    </div>
                    <div class="col-xl-4 mt-2 mb-3">
                      <img style="max-width:100%;max-height:100%;" src="@/assets/promo5.jpeg"/>  
                    </div>
                    <div class="col-xl-4 mt-2 mb-3">
                      <img style="max-width:100%;max-height:100%;" src="@/assets/promo4.jpeg"/>    
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="header bg-gradient-success pb-6" style="padding-top:100px">
            <div class="container-fluid">
              <div class="header-body" style="margin-bottom:50px">
                <!-- Card stats -->
                <statistic-view></statistic-view>
                
              </div>
            </div>
          </div>
          <div class="container-fluid mt--6">
            <div class="row">
              <div class="col-xl-8">
                <rujukan-per-bulan-view></rujukan-per-bulan-view>
              </div>
              <div class="col-xl-4">
                <tipe-pasien-view></tipe-pasien-view>
              </div>
            </div>
            <div class="row mt-5">

              <div class="col-xl-6">
                <perujuk-view></perujuk-view>
              </div>
              <div class="col-xl-6">
                <demografi-view></demografi-view>
              </div>
            </div>
          </div>
        </template>
    </div>
</template>
<script>
  import StatisticView from './Dashboard/StatisticView'
  import PerujukView from './Dashboard/PerujukView'
  import DemografiView from './Dashboard/DemografiView'
  import RujukanPerBulanView from './Dashboard/RujukanPerBulanView'
  import TipePasienView from './Dashboard/TipePasienView'
  export default {
    components: {
      StatisticView,
      PerujukView,
      DemografiView,
      RujukanPerBulanView,
      TipePasienView
    },
    data() {
      return {
        role:'',
        bigLineChart: {
          allData: [
            [0, 20, 10, 30, 15, 40, 20, 60, 60],
            [0, 20, 5, 25, 10, 30, 15, 40, 40]
          ],
          activeIndex: 0,
          chartData: {
            datasets: [],
            labels: [],
          },
        },
        redBarChart: {
          chartData: {
            labels: ['Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            datasets: [{
              label: 'Sales',
              data: [25, 20, 30, 22, 17, 29]
            }]
          }
        }
      };
    },
    methods: {
      initBigChart(index) {
        let chartData = {
          datasets: [
            {
              label: 'Performance',
              data: this.bigLineChart.allData[index]
            }
          ],
          labels: ['May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        };
        this.bigLineChart.chartData = chartData;
        this.bigLineChart.activeIndex = index;
      }
    },
    mounted() {
      if(localStorage.getItem("tokensirulin") == null){
          this.$router.push({ name: 'login' })
      }
      this.role = localStorage.getItem("role");
      this.initBigChart(0);
    }
  };
</script>
<style></style>
