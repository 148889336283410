<template>
  <div class="card shadow"
       :class="type === 'dark' ? 'bg-default': ''">
    <div class="card-header border-0"
         :class="type === 'dark' ? 'bg-transparent': ''">
      <div class="row align-items-center">
        <div class="col-md-8">
          <h3 class="mb-0" :class="type === 'dark' ? 'text-white': ''">
            {{title}}
          </h3>
        </div>
        <div class="col text-right">
           <base-input
              placeholder="Cari nama pasien"
              :formGroup="false"
              v-on:keyup.enter="getData(1)"
              v-model="search">
            </base-input>
        </div>
        <div>
            <base-button type="primary" @click="getData(1)">Cari</base-button>
        </div>
      </div>
    </div>
    
    <div style="text-align:center;width:100%" v-if="loadingData">Sedang memuat data ....</div>
    <div class="table-responsive" v-else>
      <base-table class="table align-items-center table-flush"
                  :class="type === 'dark' ? 'table-dark': ''"
                  :thead-classes="type === 'dark' ? 'thead-dark': 'thead-light'"
                  tbody-classes="list"
                  :data="tableData">
        <template slot="columns">
          <th>Nama</th>
          <th>No Telp</th>
          <th>Status</th>
          <th>Tanggal Rujukan</th>
          <!-- <th>Tindakan Akhir</th> -->
          <th></th>
        </template>

        <template slot-scope="{row}">
          <th scope="row">
            <div class="media align-items-center">
              <div class="media-body">
                <span class="name mb-0 text-sm">{{row.pasien_name}}</span>
              </div>
            </div>
          </th>
          <td class="budget">
            {{row.phone}}
          </td>
          <td>
            <badge class="badge-dot mr-4" :type="row.statusType">
              <i :class="`bg-${row.statusType}`"></i>
              <span class="status">{{statusList[row.status]}}</span>
            </badge>
          </td>
          <td>
            <div class="media-body">
                <span class="name mb-0 text-sm">{{row.rujukan_date | formatDate}}</span>
              </div>
          </td>
          <!-- <td>
            <div class="media-body">
                <span class="name mb-0 text-sm">{{row.action_last_type}}</span>
              </div>
          </td> -->
          <td>
            <div class="media-body">
                <base-button @click="showDet(row)" type="info">Detail</base-button>
                <!-- <base-button tag="a" :href="'#/edit-rujukan/' + row.id" type="primary" v-if='role == "2" && row.statusType == "success" && row.statusType != "default" '>Edit</base-button> -->
                <base-button @click="showRegistrasiModal(row)" v-if='role == "2" && row.statusType == "danger"' :type="row.statusType == 'success' ? 'danger' : 'success'">{{ row.statusType == "success" ? 'Set Belum Registrasi' : 'Set Registrasi'}}</base-button>
                <!-- <base-button @click="setClose(row)" type="default" v-if='role == "2" && row.statusType == "success" && row.statusType != "default" && row.action_last_type != "-" '>Close</base-button> -->
                <base-button @click="showRegistrasiModal(row, 'cancel')" v-if='role == "2" && row.statusType != "danger"' type="danger">Set Belum Registrasi</base-button>
              </div>
          </td>
        </template>

      </base-table>
    </div>

    <div class="card-footer d-flex justify-content-end"
         :class="type === 'dark' ? 'bg-transparent': ''">
      <base-pagination :total="meta.total"
                       :perPage="meta.per_page"
                       v-model="page"
      ></base-pagination>
    </div>

    <modal :show.sync="detailMode"
            body-classes="p-0"
            modal-classes="modal-dialog-centered modal-lg">
        <card type="secondary" shadow
              header-classes="bg-white pb-5"
              body-classes="px-lg-5 py-lg-5"
              class="border-0">
            <template>
                <h2>Rujukan</h2>
                <div class="mb-4">
                  <table cellpadding="5px">
                    <tr>
                      <td width="150px">Nama</td>
                      <td>:</td>
                      <td>{{selected.pasien_name}}</td>
                    </tr>
                    <tr>
                      <td valign="top">Alamat</td>
                      <td valign="top">:</td>
                      <td>{{selected.address + ', ' + selected.kelurahan + ', ' + selected.kecamatan + ', ' + selected.kabupaten + ', ' + selected.province}}</td>
                    </tr>
                    <tr>
                      <td>Tanggal Lahir</td>
                      <td>:</td>
                      <td>{{selected.pasien_dob | formatDate}}</td>
                    </tr>
                    <tr v-if="role != 4 && role != 3">
                      <td valign="top">Mitra</td>
                      <td valign="top">:</td>
                      <td><b>{{selected.mitra.user.name}}</b><br>Klinik : {{selected.mitra.klinik_name}}</td>
                    </tr>
                    <tr>
                      <td>Diagnosa Awal</td>
                      <td>:</td>
                      <td>{{selected.diagnosis_first}}</td>
                    </tr>
                    <tr>
                      <td>Tindakan Rujukan</td>
                      <td>:</td>
                      <td>{{selected.action_first}}</td>
                    </tr>
                    <tr>
                      <td colspan="3"><hr style="margin:5px 0"></td>
                    </tr>
                    <tr v-if="loadingDetail">
                      <td colspan="3">Sedang mengambil data diagnosa akhir....</td>
                    </tr>
                    <tr v-if="!loadingDetail">
                      <td valign="top">Diagnosa Akhir</td>
                      <td valign="top">:</td>
                      <td>
                        <template v-if="selected.diagnosa.length > 0">
                          <span v-for="(data, index) in selected.diagnosa" :key="index">
                            {{data}}<br>
                          </span>
                        </template>
                        <span v-else>
                          {{selected.diagnosa}}
                        </span>
                      </td>
                    </tr>
                    <tr v-if="!loadingDetail">
                      <td valign="top">Tindakan</td>
                      <td valign="top">:</td>
                      <td>
                        <template v-if="selected.tindakan.length > 0">
                          <span v-for="(data, index) in selected.tindakan" :key="index">
                            {{data}}<br>
                          </span>
                        </template>
                        <span v-else>
                          {{selected.tindakan}}
                        </span>
                      </td>
                    </tr>
                  </table>
                    <!-- <p>Nama: <br></p>
                    <p>Alamat: <br> {{selected.address + ', ' + selected.kelurahan + ', ' + selected.kecamatan + ', ' + selected.kabupaten + ', ' + selected.province}}</p>
                    <p>Tanggal Lahir: <br> {{selected.pasien_dob}}</p>
                    <p>Diagnosa: <br> {{selected.diagnosis_first}}</p>
                    <p>Tindakan Rujukan: <br> {{selected.action_first}}</p> -->
                <base-button type="danger" class="my-4" @click="detailMode = false">Tutup</base-button>
                </div>
                
            </template>
        </card>
    </modal>

    
    <modal :show.sync="modal.registrasi"
            body-classes="p-0"
            modal-classes="modal-dialog-centered modal-sm">
        <card type="secondary" shadow
              header-classes="bg-white pb-5"
              body-classes="px-lg-5 py-lg-5"
              class="border-0">
            <template>
                <div class="mb-4">
                    <h1>Registrasi</h1>
                    <base-input alternative
                      label="No Registrasi"
                      class="mb-3"
                      placeholder="No Registrasi"
                      v-model="noreg">
                    </base-input>
                <base-button type="primary" class="my-4" @click="setReg" :disabled="loadingProcess"><span v-if="loadingProcess">Menyimpan...</span><span v-else>Simpan</span></base-button>
                <base-button type="danger" class="my-4" @click="modal.registrasi = false">Tutup</base-button>
                </div>
                
            </template>
        </card>
    </modal>

  </div>
</template>
<script>
import moment from 'moment'
  export default {
    name: 'projects-table',
    props: {
      type: {
        type: String
      },
      title: String
    },
    data() {
      return {
        search:'',
        role: '',
        modal:{
          registrasi:false
        },
        noreg:'',
        meta: {
          total : 0,
          per_page : 0,
        },
        page: 1,
        statusList: {
          0 : "Belum Registrasi",
          1 : "Registrasi",
          2 : "Closed",
        },
        tableData : [],
        detailMode : false,
        selected: {
          pasien_name: '',
          address: '',
          kelurahan: '',
          kecamatan: '',
          kabupaten: '',
          province: '',
          pasien_dob: '',
          diagnosis_first: '',
          action_first: '',
          noreg:'',
          tindakan:[],
          diagnosa:[],
          mitra:{
            user:'',
            klinik_name:''
          }
        },
        optionMitra:[],
        loadingData:true,
        loadingProcess:false,
        loadingDetail:false
      }
    },
    filters:{
      formatDate: function (value) {
        if (!value) return ''
        value = moment(value).format("DD MMMM YYYY ");
        return value
      },
    },
    mounted() {
      this.role = localStorage.getItem("role");
      this.getData();
      this.getListMitra();
    },
    watch:{
      page(newPage){
        this.getData(newPage)
      }
    },
    methods:{
      getListMitra(){
        this.$http
        .get("/api/kliniksOption")
        .then((response) => {
          response.data.data.forEach(el => {
            this.option.push({label: el.klinik_name, id: el.id, point: el.point});
          });
          // console.log(this.option);
        });
      },
      getData(page=this.page){
        this.loadingData = true
        this.$http
          .get("/api/rujukans?search="+this.search+"&page="+page)
          .then((response) => {
            this.loadingData = false
            this.tableData = response.data.data;
            this.tableData.forEach(element => {
              if(element.status == '0'){
                element.statusType = 'danger';
              } else if(element.status == '1'){
                element.statusType = 'success';
              } else {
                element.statusType = 'default';
              }
              element.diagnosa = []
              element.tindakan = []
            });
            this.meta = response.data.meta;
          });
      },
      showRegistrasiModal(row, status='update'){
        this.selected = row;
        if(status == 'update'){
          this.modal.registrasi = true
        }else{
          this.setReg();
        }
      },
      setReg(){
        this.$swal({
          title: 'Yakin?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: 'green',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ya',
          cancelButtonText: 'Tidak'
        }).then((result) => {
          this.loadingProcess = true
          if (result.isConfirmed) {
            this.$http
            .put("/api/rujukans/"+this.selected.id, {
              'id' : this.selected.id,
              'rujukan_date' : moment(this.selected.rujukan_date).format('YYYY-MM-DD'),
              'pasien_name' : this.selected.pasien_name,
              'pasien_dob' : moment(this.selected.pasien_dob).format('YYYY-MM-DD'),
              'phone' : this.selected.phone,
              'address' : this.selected.address,
              'province' : this.selected.province,
              'kabupaten' : this.selected.kabupaten,
              'kecamatan' : this.selected.kecamatan,
              'kelurahan' : this.selected.kelurahan,
              'diagnosis_first' : this.selected.diagnosis_first,
              'action_first' : this.selected.action_first,
              'action_last_type' : "-",
              'action_last' : "-",
              'noreg': this.selected.status == '0' ? this.noreg : '',
              'status' : this.selected.status == '0' ? '1' : '0',
            })
            .then((response) =>{
              if(response.status == 200){
                this.$swal(
                  'Berhasil!',
                  'data tersimpan.',
                  'success'
                );
                this.getData()
                this.modal.registrasi = false
              }
              this.loadingProcess = false
              this.noreg = ''
              
            }).catch((err) => {
              console.log(err)
              this.loadingProcess = false
              this.errors = err.response.data.error
              this.$swal({
                title: "Gagal",
                text: err.response.data.info,
                icon: "error"
              })
            })
          }
        });
      },
      setClose(row){
        this.$swal({
          title: 'Yakin?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: 'green',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ya',
          cancelButtonText: 'Tidak'
        }).then((result) => {
          this.loadingProcess = true
          if (result.isConfirmed) {
            this.$http
            .put("/api/rujukans/"+row.id, {
              'id' : row.id,
              'rujukan_date' : moment(row.rujukan_date).format('YYYY-MM-DD'),
              'pasien_name' : row.pasien_name,
              'pasien_dob' : moment(row.pasien_dob).format('YYYY-MM-DD'),
              'phone' : row.phone,
              'address' : row.address,
              'province' : row.province,
              'kabupaten' : row.kabupaten,
              'kecamatan' : row.kecamatan,
              'kelurahan' : row.kelurahan,
              'diagnosis_first' : row.diagnosis_first,
              'action_first' : row.action_first,
              'action_last' : row.action_last,
              'diagnosis_last' : row.diagnosis_last,
              'status' : '2',
            })
            .then((response) =>{
              this.loadingProcess = false
              if(response.status == 200){
                this.$swal(
                  'Berhasil!',
                  'data tersimpan.',
                  'success'
                );
                this.getData()
              }
            }).catch((err) => { 
              this.loadingProcess = false
              console.log(err)
              this.$swal(
                'Gagal',
                'Update data gagal',
                'danger'
              )
            })
          }
        });
      },
      showDet(row){
        console.log(row);
        this.selected = row;
        this.detailMode = true;
        this.loadingDetail = true
        this.$http
          .get("/api/rujukan/status/"+row.id)
          .then((response) => {
            this.loadingDetail = false
            this.selected.diagnosa = response.data.diagnosa
            this.selected.tindakan = response.data.tindakan
          }).catch((err) => { 
            this.loadingDetail = false
            console.log(err)
          })
      }
    }
  }
</script>
<style>
</style>
