<template>
    <div class="row">
        <div class="col-xl-3 col-md-6">
        <div class="card card-stats">
            <!-- Card body -->
            <div class="card-body">
            <div class="row">
                <div class="col">
                <h5 class="card-title text-uppercase text-muted mb-0">Total Mitra</h5>
                <span class="h2 font-weight-bold mb-0">{{totalMitra}}</span>
                </div>
                <div class="col-auto">
                <div class="icon icon-shape bg-gradient-red text-white rounded-circle shadow">
                    <i class="ni ni-active-40"></i>
                </div>
                </div>
            </div>
            <p class="mt-3 mb-0 text-sm">
                <template v-if="totalMitraBaru > 0">
                    <span class="text-success mr-2">{{totalMitraBaru}}</span>
                    <span class="text-nowrap">Mitra baru ditambahkan bulan ini</span>
                </template>
                <template v-else>
                    <span class="text-nowrap">Tidak ada mitra baru</span>
                </template>
            </p>
            </div>
        </div>
        </div>
        <div class="col-xl-3 col-md-6">
        <div class="card card-stats">
            <!-- Card body -->
            <div class="card-body">
            <div class="row">
                <div class="col">
                <h5 class="card-title text-uppercase text-muted mb-0">Rujukan</h5>
                <span class="h2 font-weight-bold mb-0">{{totalRujukan}}</span>
                </div>
                <div class="col-auto">
                <div class="icon icon-shape bg-gradient-orange text-white rounded-circle shadow">
                    <i class="ni ni-chart-pie-35"></i>
                </div>
                </div>
            </div>
            <p class="mt-3 mb-0 text-sm">
                <span class="text-success mr-2">{{totalPresentaseRujukan}}%</span>
                <span class="text-nowrap">Telah Registrasi</span>
            </p>
            </div>
        </div>
        </div>
        <div class="col-xl-3 col-md-6">
        <div class="card card-stats">
            <!-- Card body -->
            <div class="card-body">
            <div class="row">
                <div class="col">
                <h5 class="card-title text-uppercase text-muted mb-0">Redeem</h5>
                <span class="h2 font-weight-bold mb-0">{{totalRedeem}}</span>
                </div>
                <div class="col-auto">
                <div class="icon icon-shape bg-gradient-green text-white rounded-circle shadow">
                    <i class="ni ni-money-coins"></i>
                </div>
                </div>
            </div>
            <p class="mt-3 mb-0 text-sm">
                <span class="text-success mr-2">{{totalBenefit}}</span>
                <span class="text-nowrap">Jenis item Redeem</span>
            </p>
            </div>
        </div>
        </div>
        <div class="col-xl-3 col-md-6">
        <div class="card card-stats">
            <!-- Card body -->
            <div class="card-body">
            <div class="row">
                <div class="col">
                <h5 class="card-title text-uppercase text-muted mb-0">Keaktifan</h5>
                <span class="h2 font-weight-bold mb-0">{{presentaseMitraAktif}}%</span>
                </div>
                <div class="col-auto">
                <div class="icon icon-shape bg-gradient-info text-white rounded-circle shadow">
                    <i class="ni ni-chart-bar-32"></i>
                </div>
                </div>
            </div>
            <p class="mt-3 mb-0 text-sm">
                <span class="text-success mr-2" :class="{ 'text-success': (perubahanPresentase > 0), 'text-danger' : (perubahanPresentase < 0) }">
                    <i v-if="perubahanPresentase < 0" class="fa fa-arrow-down"></i> 
                    <i v-else class="fa fa-arrow-up"></i> 
                    {{perubahanPresentase}}%
                </span>
                <span class="text-nowrap">Periode lalu</span>
            </p>
            </div>
        </div>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return{
            loadingData: false,
            totalMitra: 0,
            totalMitraBaru: 0,
            totalRujukan: 0,
            totalPresentaseRujukan: 0,
            totalRedeem: 0,
            totalBenefit: 0,
            presentaseMitraAktif: 0,
            perubahanPresentase: 0
        }
    },
    mounted(){
        this.getData()
    },
    methods:{
        getData(){
            this.loadingData = true
            this.$http
            .get("/api/dashboard/statistik")
            .then((response) => {
                this.totalMitra = response.data.totalMitra
                this.totalMitraBaru = response.data.totalMitraBaru
                this.totalRujukan = response.data.totalRujukan
                this.totalPresentaseRujukan = response.data.totalPresentaseRujukan
                this.totalRedeem = response.data.totalRedeem
                this.totalBenefit = response.data.totalBenefit
                this.presentaseMitraAktif = response.data.presentaseMitraAktif
                this,this.perubahanPresentase = response.data.presentaseMitraAktif - response.data.presentaseMitraAktifBulanLalu
            })
        }
    }
}
</script>