<template>
  <div class="card shadow"
       :class="type === 'dark' ? 'bg-default': ''">
    <div class="card-header border-0"
         :class="type === 'dark' ? 'bg-transparent': ''">
      <div class="row align-items-center">
        <div class="col">
          <h3 class="mb-0" :class="type === 'dark' ? 'text-white': ''">
            {{title}}
          </h3>
        </div>
        <div class="col text-right">
          <base-button type="info" @click="showAddModal()">Tambah Baru</base-button>
        </div>
      </div>
    </div>
    <div style="text-align:center;width:100%" v-if="loadingData">Sedang memuat data ....</div>
    <div class="table-responsive" v-else>
      <base-table class="table align-items-center table-flush"
                  :class="type === 'dark' ? 'table-dark': ''"
                  :thead-classes="type === 'dark' ? 'thead-dark': 'thead-light'"
                  tbody-classes="list"
                  :data="tableData">
        <template slot="columns">
          <th>Item</th>
          <th>Performance</th>
          <th></th>
        </template>

        <template slot-scope="{row}">
          <th scope="row">
            <div class="media align-items-center">
              <div class="media-body">
                <span class="name mb-0 text-sm">{{row.item_name}}</span>
              </div>
            </div>
          </th>
          <td class="budget">
            {{row.point}}
          </td>
          <td>
            <div class="media-body">
                <base-button @click="showEditModal(row)" type="info">Edit</base-button>
                <base-button type="danger" @click="showNonAktifModal(row)">Delete</base-button>
              </div>
          </td>

        </template>

      </base-table>
    </div>

    <div class="card-footer d-flex justify-content-end"
         :class="type === 'dark' ? 'bg-transparent': ''">
      <base-pagination :total="meta.total"
      :perPage="meta.per_page"
                       v-model="page"
      ></base-pagination>
    </div>

    <modal :show.sync="modalStatus"
            body-classes="p-0"
            modal-classes="modal-dialog-centered modal-sm">
        <card type="secondary" shadow
              header-classes="bg-white pb-5"
              body-classes="px-lg-5 py-lg-5"
              class="border-0">
            <template>
                <div class="text-muted mb-4">
                    <h1 v-if="modal.edit">Edit Benefit</h1>
                    <h1 v-else-if="modal.add">Tambah Benefit</h1>
                    <form role="form">
                    <base-input alternative
                                label="Item"
                                class="mb-3"
                                v-model="model.item_name"
                                :error="errors.item_name"
                                :valid="(errors.item_name)?false:null"
                                placeholder="Item">
                    </base-input>
                    <base-input alternative
                    type="number"
                                label="Performance"
                                min="0"
                                v-model="model.point"
                                :error="errors.point"
                                :valid="(errors.point)?false:null"
                                placeholder="Performance">
                    </base-input>
                    <div class="text-center">
                        <base-button type="primary" class="my-4" @click="(modal.edit)?updateData():saveData()" :disabled="loadingProcess">
                          <span v-if="loadingProcess">Menyimpan...</span><span v-else>Simpan</span>
                        </base-button>
                        <base-button type="danger" class="my-4" @click="closeModal((modal.edit)?'edit':'add')">Batal</base-button>
                    </div>
                </form>
                </div>
                
            </template>
        </card>
    </modal>
  </div>
</template>
<script>
import BaseButton from '../../components/BaseButton.vue'
  export default {
  components: { BaseButton },
    name: 'projects-table',
    props: {
      type: {
        type: String
      },
      title: String
    },
    data() {
      return {
        tableData: [
        ],
        role: '',
        meta: {
          total : 0,
          per_page : 0,
        },
        page: 1,
        benefitAdd : false,
        model:{
          item_name : '',
          point: '',
        },
        modal:{
          add:false,
          edit:false
        },
        selected: null,
        loadingData:true,
        loadingProcess:false,
        errors:[] 
      }
    },
    
    mounted() {
      this.role = localStorage.getItem("role");
      this.getData()
    },
    watch:{
      page(newPage){
        this.getData(newPage)
      }
    },
    computed:{
      modalStatus: {
        get(){
          return this.modal.add || this.modal.edit
        },
        set(status){
          return status
        } 
      }
    },
    methods:{
      getData(page=this.page){
        this.loadingData = true
        this.$http
          .get("/api/benefits?page="+page)
          .then((response) => {
            this.loadingData = false
            this.tableData = response.data.data;
            this.meta = response.data.meta;
          });
      },
      resetModel(){
        Object.keys(this.model).forEach(k => this.model[k] = null);
      },
      closeModal(type){
        if(type == 'edit'){
          this.selected = null
          this.modal.edit = false
          this.resetModel()
        }else{
          this.modal.add = false
        }
      },
      showEditModal(value){
        this.errors = []
        this.selectedData = Object.assign({},value)
        Object.keys(this.model).forEach(k => this.model[k] = value[k]);
        this.modal.edit = true
      },
      showAddModal(){
        this.errors = []
        this.modal.add = true
      },
      showNonAktifModal(value){
        let self = this
        this.$swal({
          title: 'Nonaktifkan akun',
          text: 'Apakah anda yakin nonaktifkan user ini?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ya',
          cancelButtonText: 'Tidak'
        }).then((result) => {
          if (result.isConfirmed) {
            self.nonAktifData(value.id)
          }
        });
      },
      nonAktifData(id){
        this.$http
        .delete("/api/benefits/"+id)
        .then(() => {
          this.$swal({
            title: "Berhasil",
            text: "Data Mitra berhasil dinonaktifkan",
            icon: "success"
          });
          this.getData()
        }).catch((error) => {
          this.errors = error.response.data.error
          this.$swal({
            title: "Gagal",
            text: error.response.data.info,
            icon: "error"
          })
        });
      },
      updateData(){
        this.loadingProcess = true
        this.$http
        .put("/api/benefits/"+this.selectedData.id, {
          'item_name' : this.model.item_name,
          'point' : this.model.point,
        })
        .then((response) => {
          this.loadingProcess = false
          this.$swal({
            title: "Berhasil",
            text: response.data.info,
            icon: "success"
          });
          this.getData()
          this.resetModel()
          this.closeModal('edit')
        }).catch((error) => {
          this.loadingProcess = false
          this.errors = error.response.data.error
          this.$swal({
            title: "Gagal",
            text: error.response.data.info,
            icon: "error"
          })
        });
      },
      saveData(){
        this.loadingProcess = true
        this.$http
        .post("/api/benefits", {
          'item_name' : this.model.item_name,
          'point' : this.model.point,
        })
        .then((response) => {
          this.loadingProcess = false
          this.$swal({
            title: "Berhasil",
            text: response.data.info,
            icon: "success"
          });
          this.getData()
          this.resetModel()
          this.closeModal('add')
        }).catch((error) => {
          this.loadingProcess = false
          this.errors = error.response.data.error
          this.$swal({
            title: "Gagal",
            text: error.response.data.info,
            icon: "error"
          })
        });
      },
      deleteData(row){
        this.loadingProcess = false
        this.$http
        .delete("/api/benefits/"+row.id)
        .then((response) => {
          this.$swal({
            title: "Berhasil",
            text: response.data.info,
            icon: "success"
          })
          this.userAdd = false;
          this.getData()
        }).catch(() => {
          this.loadingProcess = false
          this.$swal({
            title: "Gagal",
            text: "Ada Kesalahan Pengiriman Data",
            icon: "error"
          })
        });
      }
    }
  }
</script>
<style>
</style>
