<template>
    <div>
        <div class="card">
            <div class="card-header">
                <!-- Title -->
                <h5 class="h3 mb-0">Top 10 Bidan Perujuk</h5>
            </div>
            <div class="card-body bg-transparent" style="padding:0">
                <div class="row align-items-center" >
                    <div class="col">
                        <div style="text-align:center;width:100%" v-if="loadingData">Sedang memuat data ....</div>
                        <div class="table-responsive" v-else>
                            <base-table class="table align-items-center table-flush"
                                :class="''"
                                :thead-classes="'thead-light'"
                                tbody-classes="list"
                                :data="tableData">
                                <template slot="columns">
                                    <th>Nama</th>
                                    <th>Jumlah Rujukan</th>
                                </template>

                                <template slot-scope="{row}">
                                    <th scope="row">
                                        <div class="media align-items-center">
                                        <div class="media-body">
                                            <span class="name mb-0 text-sm">{{row.name}}</span>
                                            <br>Klinik : <span style="font-weight:normal;font-style:italic">{{row.klinik_name}}</span>
                                        </div>
                                        </div>
                                    </th>
                                    <td class="budget">
                                        {{row.jumlah}}
                                    </td>
                                </template>

                            </base-table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
      return {
        tableData: [],
        loadingData:false,
        meta:null,
      }
    },
    mounted() {
        this.getData()
    },
    methods:{
        getData(){
            this.loadingData = true
            this.$http
            .get("/api/dashboard/perujuk")
            .then((response) => {
                this.loadingData = false
                this.tableData = response.data.data;
                this.meta = response.data.meta;
            });
      },
    }
}
</script>