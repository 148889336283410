<template>
        <div class="row justify-content-center">
            <div class="col-lg-5 col-md-7">
                <div class="card bg-secondary shadow border-0">
                    <!-- <h3 class="text-center">Login</h3> -->
                    <div class="card-body px-lg-5 pt-lg-5">
                        <form role="form" >
                            <base-input class="input-group-alternative mb-3"
                                        placeholder="Username"
                                        addon-left-icon="ni ni-circle-08"
                                        v-model="model.username"
                                        v-on:keyup.enter="login">
                            </base-input>

                            <base-input class="input-group-alternative"
                                        placeholder="Password"
                                        type="password"
                                        addon-left-icon="ni ni-lock-circle-open"
                                        v-model="model.password"
                                        v-on:keyup.enter="login">
                            </base-input>
                            <div class="text-center">
                                <base-button type="primary" class="my-4" @click="login" :disabled="isLoading">
                                    <span v-if="!isLoading">Sign in</span>
                                    <span v-else>Sign In....</span>
                                </base-button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
</template>
<script>
  export default {
    name: 'login',
    data() {
      return {
        model: {
          username: '',
          password: ''
        },
        isLoading:false,
      }
    },
    methods: {
        login() {
            var bodyFormData = new FormData();
            bodyFormData.set('username', this.model.username);
            bodyFormData.set('password', this.model.password);
            bodyFormData.set('fcm_token', localStorage.getItem('fcm_token'))
            this.isLoading = true
            this.$http({
                method: 'post',
                url: "/api/login",
                data: bodyFormData,
                headers: {'Content-Type': 'multipart/form-data' }
            }).then(response => {
                if(response.status == 200){
                    this.$http.defaults.headers.common['Authorization'] = "Bearer " + response.data.token.token
                    localStorage.setItem("tokensirulin",response.data.token.token)
                    localStorage.setItem("role",response.data.user.role)
                    localStorage.setItem("user_id",response.data.user.id)
                    this.username = ''
                    this.password = ''
                    this.$router.push("/")
                    this.isLoading = false
                }
                else{
                    this.isLoading = false
                    this.$swal('Ups!', "Username / Password tidak sesuai!", 'error')
                    this.password = ''
                } 
            }).catch(() => {
                this.isLoading = false
                this.$swal('Ups!', "Username/Password salah", 'error')
                this.password = ''
            });
        }
    }
  }
</script>
<style>
</style>
