<template>
    <div class="card">
        <div class="card-body bg-transparent">
            <div class="row align-items-center" >
                <div class="col">
                    <div>
                        <div style="text-align:center;width:100%" v-if="loadingData">Sedang memuat data ....</div>
                        <highcharts v-else :options="tipeTindakanPasienOptions" style="width:100%;"></highcharts>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return{
            tableData:[],
            loadingData:false,
            tipeTindakanPasienOptions:{
                chart: {
                    plotBackgroundColor: null,
                    plotBorderWidth: null,
                    plotShadow: false,
                    type: 'pie'
                },
                title: {
                    text: 'Pemetaan Tipe Tindakan'
                },
                tooltip: {
                    pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b> <br><span>Jumlah : {point.jumlah}</span>'
                },
                accessibility: {
                    point: {
                        valueSuffix: '%'
                    }
                },
                plotOptions: {
                    pie: {
                        allowPointSelect: true,
                        cursor: 'pointer',
                        dataLabels: {
                            enabled: false
                        },
                        showInLegend: true
                    }
                },
                series: [{
                    name: 'Presentase Jumlah Rujukan',
                    colorByPoint: true,
                    data: []
                }]
            }
        }
    },
    mounted(){
        this.getData()
    },
    methods:{
        getData(){
            this.loadingData = true
            this.$http
            .get("/api/dashboard/tindakan")
            .then((response) => {
                this.loadingData = false
                this.tableData = response.data.data;

                this.tipeTindakanPasienOptions.series[0].data = []
                let demografiData = []
                Array.prototype.forEach.call(this.tableData, element => {
                    demografiData.push({
                        name: element.tipe,
                        y: element.presentase,
                        jumlah: element.jumlah,
                        sliced: (element.presentase > 50)?true:false
                    })   
                })
                this.tipeTindakanPasienOptions.series[0].data = demografiData

            });
        }
    }
}
</script>