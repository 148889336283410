<template>
  <div class="card shadow"
       :class="type === 'dark' ? 'bg-default': ''">
    <div class="card-header border-0"
         :class="type === 'dark' ? 'bg-transparent': ''">
      <div class="row align-items-center">
        <div class="col">
          <h3 class="mb-0" :class="type === 'dark' ? 'text-white': ''">
            {{title}}
          </h3>
        </div>
        <div class="col text-right">
          <base-button class="mb-0" type="primary" @click="redeemAdd = true">Buat Data Redeem</base-button>
        </div>
      </div>
    </div>
    <div style="text-align:center;width:100%" v-if="loadingData">Sedang memuat data ....</div>
    <div class="table-responsive" v-else>
      <base-table class="table align-items-center table-flush"
                  :class="type === 'dark' ? 'table-dark': ''"
                  :thead-classes="type === 'dark' ? 'thead-dark': 'thead-light'"
                  tbody-classes="list"
                  :data="tableData">
        <template slot="columns">
          <th>Mitra</th>
          <th>Item</th>
          <th>Status</th>
          <th>Tanggal</th>
          <th></th>
        </template>

        <template slot-scope="{row}">
          <th scope="row">
            <div class="media align-items-center">
              <div class="media-body">
                <span class="name mb-0 text-sm">{{row.benefit.item_name + " (" + row.benefit.point + " point)"}}</span>
              </div>
            </div>
          </th>
          <td class="budget">
            {{row.klinik.klinik_name}}
          </td>
          <td>
            <badge class="badge-dot mr-4" :type="row.status != 'Open' ? (row.status != 'Dibatalkan' ? 'success' :'danger') :'info'">
              <i :class="`bg-${row.status != 'Open' ? (row.status != 'Dibatalkan' ? 'success' :'danger') :'info'}`"></i>
              <span class="status">{{row.status}}</span>
            </badge>
          </td>
          <td>
            <div class="media-body">
                <span class="name mb-0 text-sm">{{moment(row.date).format("DD MMMM YYYY")}}</span>
              </div>
          </td>
          <td>
            <div class="media-body" v-if="row.status == 'Open'">
                <base-button type="info" @click="Setuju(row)">Selesai</base-button>
                <base-button type="danger" @click="Cancel(row)">Batalkan</base-button>
              </div>
          </td>
        </template>

      </base-table>
    </div>

    <div class="card-footer d-flex justify-content-end"
         :class="type === 'dark' ? 'bg-transparent': ''">
      <base-pagination :total="meta.total"
                       :perPage="meta.per_page"
                       v-model="page"
      ></base-pagination>
    </div>
    <modal :show.sync="redeemAdd"
            body-classes="p-0"
            modal-classes="modal-dialog-centered modal-sm">
        <card type="secondary" shadow
              header-classes="bg-white pb-5"
              body-classes="px-lg-5 py-lg-5"
              class="border-0">
            <template>
                <div class="text-muted mb-4">
                    <form role="form">
                    <base-input alternative=""
                                                    label="Mitra">
                                            <v-select :options="option" v-model="selected"></v-select>
                                        </base-input>
                                        <p v-if="selected != null">Point: {{selected.point}}</p>
                    <base-input alternative=""
                                                    label="Item">
                                            <v-select :options="option2" v-model="selected2"></v-select>
                                        </base-input>
                    <div class="text-center">
                        <base-button type="primary" class="my-4" :disabled="isDis || loadingProcess" @click="saveData">
                           <span v-if="loadingProcess">Menyimpan...</span><span v-else>Simpan</span>
                        </base-button>
                        <base-button type="danger" class="my-4" @click="function(){redeemAdd = false; selected = null; selected2 = null}">Batal</base-button>
                    </div>
                </form>
                </div>
                
            </template>
        </card>
    </modal>
  </div>
</template>
<script>
import BaseButton from '../../components/BaseButton.vue';
import moment from 'moment';
  export default {
  components: { BaseButton },
    name: 'projects-table',
    props: {
      type: {
        type: String
      },
      title: String
    },
    data() {
      return {
        role: '',
        redeemAdd: false,
        selected: null,
        selected2: null,
        meta: {
          total : 0,
          per_page : 0,
        },
        page: 1,
        model:{
          benefit_id : '',
          klinik_id: '',
        },
        option:[],
        option2:[],
        tableData: [],
        loadingData: true,
        loadingProcess:false
      }
    },
    watch:{
      page(newVal){
        // console.log(newVal)
        this.$http
        .get("/api/redeems?page="+newVal)
        .then((response) => {
          this.tableData = response.data.data;
          this.meta = response.data.meta;
        });
      }
    },
    mounted(){
      this.getData()
      this.$http
      .get("/api/kliniksOption")
      .then((response) => {
        response.data.data.forEach(el => {
          this.option.push({label: el.klinik_name, id: el.id, point: el.point});
        });
        // console.log(this.option);
      });
      this.$http
      .get("/api/benefitsOption")
      .then((response) => {
        response.data.data.forEach(el => {
          this.option2.push({label: el.item_name + "("+ el.point +" Point)", id: el.id, point: el.point});
        });
        // console.log(this.option);
      });
    },
    computed: {
      isDis(){
        var val = null
        this.selected != null && this.selected2 != null ? (parseInt(this.selected.point) >= parseInt(this.selected2.point) ? val = false : val = true) : val = true
        // console.log(this.selected);
        // console.log(this.selected2);
        // console.log(val);
        return val
      }
    },
    methods:{
      moment: function () {
        return moment();
      },
      getData(page=this.page){
        this.loadingData = true
        this.$http
          .get("/api/redeems?page="+page)
          .then((response) => {
            this.loadingData = false
            this.tableData = response.data.data;
            this.meta = response.data.meta;
          });
      },
      Cancel(row){
        this.$swal({
          title: 'Yakin Membatalkan?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ya',
          cancelButtonText: 'Tidak'
        }).then((result) => {
          if (result.isConfirmed) {
            this.loadingProcess = true
            this.$http
            .get("/api/redeems/cancel/"+row.id)
            .then((response) => {
            this.loadingProcess = false
              if(response.status == 200){
                this.$swal(
                  'Berhasil!',
                  'Redeem dibatalkan.',
                  'success'
                )
              } else {
                this.$swal({
                  title: "Gagal",
                  text: "Ada Kesalahan Pengiriman Data",
                  icon: "error"
                })
              }
            }).catch((err) => {
              console.log(err)
              this.loadingProcess = false
              this.$swal({
                title: "Gagal",
                text: "Ada Kesalahan Pengiriman Data",
                icon: "error"
              })
            });
          }
        });
      },
      Setuju(row){
        this.$swal({
          title: 'Yakin Menyetujui?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ya',
          cancelButtonText: 'Tidak'
        }).then((result) => {
          this.loadingProcess = true
          if (result.isConfirmed) {
            this.$http
            .get("/api/redeems/ok/"+row.id)
            .then((response) => {
              this.loadingProcess = false
              if(response.status == 200){
                this.$swal(
                  'Berhasil!',
                  'data tersimpan.',
                  'success'
                )
              } else {
                this.$swal({
                  title: "Gagal",
                  text: "Ada Kesalahan Pengiriman Data",
                  icon: "error"
                })
              }
            }).catch((err) => {
              console.log(err)
              this.loadingProcess = false
              this.$swal({
                title: "Gagal",
                text: "Ada Kesalahan Pengiriman Data",
                icon: "error"
              })
            });
          }
        });
      },
      saveData(){
        this.loadingProcess = true
        this.$http
        .post("/api/redeems", {
          'klinik_id' : this.selected.id,
          'benefit_id' : this.selected2.id,
        })
        .then((response) => {
          this.loadingProcess = false
          if(response.status == 201){
            this.$swal({
              title: "Berhasil",
              text: "Data Benefit ditambahkan",
              icon: "success"
            })
            this.benefitAdd = false;
            this.$http
            .get("/api/benefits")
            .then((response) => {
              this.tableData = response.data.data;
              this.meta = response.data.meta;
            });
          } else {
            this.$swal({
              title: "Gagal",
              text: "Ada Kesalahan Pengiriman Data",
              icon: "error"
            })
          }
        }).catch((err) => {
          console.log(err)
          this.loadingProcess = false
          this.$swal({
            title: "Gagal",
            text: "Ada Kesalahan Pengiriman Data",
            icon: "error"
          })
        });
      },
    },
  }
</script>
<style>
</style>
