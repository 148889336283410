<template>
  <div class="card shadow"
       :class="type === 'dark' ? 'bg-default': ''">
    <div class="card-header border-0"
         :class="type === 'dark' ? 'bg-transparent': ''">
      <div class="row align-items-center">
        <div class="col">
          <h3 class="mb-0" :class="type === 'dark' ? 'text-white': ''">
            {{title}}
          </h3>
        </div>
        <div class="col text-right">
          <base-button class="mb-0" v-if="role != 1" type="primary" @click="showAddModal()">Buat Data Mitra</base-button>
        </div>
      </div>
    </div>

    <div style="text-align:center;width:100%" v-if="loadingData">Sedang memuat data ....</div>
    <div class="table-responsive" v-else>
      <base-table class="table align-items-center table-flush"
                  :class="type === 'dark' ? 'table-dark': ''"
                  :thead-classes="type === 'dark' ? 'thead-dark': 'thead-light'"
                  tbody-classes="list"
                  :data="tableData">
        <template slot="columns">
          <th>Nama</th>
          <th>Username</th>
          <th>Klinik</th>
          <th>No Telp</th>
          <th>Alamat</th>
          <th v-if="role == '1'">PIC</th>
          <th>Point</th>
          <th></th>
        </template>

        <template slot-scope="{row}">
          <th scope="row">
            <div class="media align-items-center">
              <div class="media-body">
                <span class="name mb-0 text-sm">{{row.user.name}}</span>
              </div>
            </div>
          </th>
          <td class="budget">
            {{row.user.username}}
          </td>
          <td class="budget">
            {{row.klinik_name}}
          </td>
          <td class="budget">
            {{row.klinik_phone}}
          </td>
          <td class="budget">
            {{row.klinik_address}}
          </td>
          <td v-if="role == '1'" class="budget">
            {{row.pic.pic_name}}
          </td>
          <td class="budget">
            {{row.point}}
          </td>
          <td>
            <div class="media-body">
                <base-button @click="showEditModal(row)" type="info">Edit</base-button>
                <base-button @click="showNonAktifModal(row)" type="danger">Nonaktifkan</base-button>
              </div>
          </td>

        </template>

      </base-table>
    </div>

    <div class="card-footer d-flex justify-content-end"
         :class="type === 'dark' ? 'bg-transparent': ''">
      <base-pagination :total="meta.total"
                       :perPage="meta.per_page"
                       v-model="page"
      ></base-pagination>
    </div>

    <modal :show.sync="modalStatus"
            body-classes="p-0"
            modal-classes="modal-dialog-centered modal-md">
        <card type="secondary" shadow
              header-classes="bg-white pb-5"
              body-classes="px-lg-5 py-lg-5"
              class="border-0">
            <template >
                <div class="text-muted mb-4">
                    <h1 v-if="modal.edit">Edit Mitra</h1>
                    <h1 v-else-if="modal.add">Tambah Mitra</h1>
                    <form role="form">
                      <base-input alternative
                                label="Nama Mitra"
                                class="mb-3"
                                placeholder="Nama"
                                :error="errors.name"
                                :valid="(errors.name)?false:null"
                                v-model="model.name">
                      </base-input>
                      <base-input alternative
                                  label="Username"
                                  class="mb-3"
                                  placeholder="Username"
                                  :error="errors.username"
                                  :valid="(errors.username)?false:null"
                                v-model="model.username">
                      </base-input>
                      <base-input alternative
                                  label="Nama Tempat Praktek"
                                  class="mb-3"
                                  placeholder="Nama Tempat Praktek"
                                  :error="errors.klinik_name"
                                  :valid="(errors.klinik_name)?false:null"
                                v-model="model.klinik_name">
                      </base-input>
                      <base-input alternative
                                  label="Alamat Tempat Praktek"
                                  class="mb-3"
                                  placeholder="Alamat Tempat Praktek"
                                  :error="errors.klinik_address"
                                  :valid="(errors.klinik_address)?false:null"
                                  v-model="model.klinik_address">
                      </base-input>
                      <base-input alternative
                                  label="No Telp"
                                  class="mb-3"
                                  placeholder="No Telp"
                                  :error="errors.klinik_phone"
                                  :valid="(errors.klinik_phone)?false:null"
                                  v-model="model.klinik_phone">
                      </base-input>
                      <base-input alternative=""
                                  v-if="role != '4'"
                                  :error="errors.pic_id"
                                  :valid="(errors.pic_id)?false:null"
                                  label="PIC">
                        <v-select :options="option" v-model="selected"></v-select>
                      </base-input>
                    <div class="text-center">
                        <base-button type="primary" class="my-4" @click="(modal.edit)?updateData():saveData()" :disabled="loadingProcess">
                          <span v-if="loadingProcess">Menyimpan...</span><span v-else>Simpan</span>
                        </base-button>
                        <base-button type="danger" class="my-4" @click="closeModal((modal.edit)?'edit':'add')">Batal</base-button>
                    </div>
                </form>
                </div>
                
            </template>
        </card>
    </modal>

  </div>
</template>
<script>
  export default {
    name: 'projects-table',
    props: {
      type: {
        type: String
      },
      title: String
    },
    data() {
      return {
        role: '',
        meta: {
          total : 0,
          per_page : 0,
        },
        page: 1,
        mitraAdd : false,
        option:[],
        tableData : [],
        model:{
          name : '',
          username:'',
          klinik_name:'',
          klinik_address:'',
          klinik_phone:'',
        },
        modal:{
          add:false,
          edit:false
        },
        selected: null,
        selectedData: null,
        loadingData:true,
        loadingProcess:false,
        errors:[] 
      }
    },
    mounted() {
      this.role = localStorage.getItem("role");
      this.getData()
      this.$http
      .get("/api/pics")
      .then((response) => {
        response.data.data.forEach(el => {
          this.option.push({label: el.pic_name, id: el.id, user_id: el.user_id});
        });
        console.log(this.option);
      });
    },
    watch:{
      page(newPage){
        this.getData(newPage)
      }
    },
    computed:{
      modalStatus: {
        get(){
          return this.modal.add || this.modal.edit
        },
        set(status){
          return status
        } 
      }
    },
    methods:{
      getData(page=this.page){
        this.loadingData = true
        this.$http
          .get(this.role != '4' ? "/api/kliniks?page="+page : "/api/kliniksPIC/"+localStorage.getItem("user_id")+"?page="+page)
          .then((response) => {
            this.loadingData = false
            this.tableData = response.data.data;
            this.meta = response.data.meta;
          });
      },
      showNonAktifModal(value){
        let self = this
        this.$swal({
          title: 'Nonaktifkan akun',
          text: 'Apakah anda yakin nonaktifkan user ini?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ya',
          cancelButtonText: 'Tidak'
        }).then((result) => {
          if (result.isConfirmed) {
            self.nonAktifData(value.id)
          }
        });
      },
      nonAktifData(id){
        this.$http
        .delete("/api/kliniks/"+id)
        .then(() => {
          this.$swal({
            title: "Berhasil",
            text: "Data Mitra berhasil dinonaktifkan",
            icon: "success"
          });
          this.getData()
        }).catch((error) => {
          this.errors = error.response.data.error
          this.$swal({
            title: "Gagal",
            text: error.response.data.info,
            icon: "error"
          })
        });
      },
      showEditModal(value){
        this.errors = []
        this.selectedData = Object.assign({},value)
        Object.keys(this.model).forEach(k => this.model[k] = value[k]);
        this.model.name = this.selectedData.user.name
        this.model.username = this.selectedData.user.username
        this.selected = {label: value.pic.pic_name, id: value.pic.id, user_id: value.pic.user_id}
        this.modal.edit = true
      },
      showAddModal(){
        this.errors = []
        this.modal.add = true
      },
      resetModel(){
        Object.keys(this.model).forEach(k => this.model[k] = null);
      },
      closeModal(type){
        if(type == 'edit'){
          this.selected = null
          this.modal.edit = false
          this.resetModel()
        }else{
          this.modal.add = false
        }
      },
      resetPass(){
        this.$swal({
          title: 'Apakah Anda Yakin?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ya',
          cancelButtonText: 'Tidak'
        }).then((result) => {
          if (result.isConfirmed) {
            this.$swal(
              'Berhasil!',
              'Password di-reset.',
              'success'
            )
          }
        });
      },
      updateData(){
        this.loadingProcess = true
        this.$http
        .put("/api/kliniks/"+this.selectedData.id, {
          'nama' : this.model.name,
          'username' : this.model.username,
          'klinik_name' : this.model.klinik_name,
          'klinik_address' : this.model.klinik_address,
          'klinik_phone' : this.model.klinik_phone,
          'pic_id' : this.role != "4" ? this.selected.id : this.option.find(x => String(x.user_id) == localStorage.getItem("user_id")).id,
        })
        .then(() => {
          this.loadingProcess = false
          this.$swal({
            title: "Berhasil",
            text: "Data Mitra diperbaharui",
            icon: "success"
          });
          this.getData()
          this.resetModel()
          this.closeModal('edit')
        }).catch((error) => {
          this.loadingProcess = false
          this.errors = error.response.data.error
          this.$swal({
            title: "Gagal",
            text: error.response.data.info,
            icon: "error"
          })
        });
      },
      saveData(){
        this.loadingProcess = true
        this.$http
        .post("/api/kliniks", {
          'nama' : this.model.name,
          'username' : this.model.username,
          'klinik_name' : this.model.klinik_name,
          'klinik_address' : this.model.klinik_address,
          'klinik_phone' : this.model.klinik_phone,
          'pic_id' : this.role != "4" ? this.selected.id : this.option.find(x => String(x.user_id) == localStorage.getItem("user_id")).id,
        })
        .then((response) => {
          this.loadingProcess = false
          this.$swal({
            title: "Berhasil",
            text: response.data.info,
            icon: "success"
          });
          this.getData()
          this.resetModel()
          this.closeModal('add')
        }).catch((error) => {
          
          this.loadingProcess = false
          this.errors = error.response.data.error
          this.$swal({
            title: "Gagal",
            text: error.response.data.info,
            icon: "error"
          })
        });
      }
    }
  }
</script>
<style>
</style>
